import { Pipe, PipeTransform } from "@angular/core";
import { ValidatePromoCodeVM } from "../../components/rn-business-components/change-package-inventory/validate-promo-code-vm";
import { PackageCardData } from "../../components/rn-business-components/package-card/package-card-data";

@Pipe({
  name: "rnPackageInventory",
})
export class RnPackageInventoryPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (args && args.length > 0) {
      const prop = args[0].toString().toLowerCase();
      switch (prop) {
        case "currestbill": {
          const promo = value as ValidatePromoCodeVM;
          const discountedBalance = args[1] as number;
          const currentMonthlyBill = args[2] as number;

          return this.getCurrentBalance(
            promo,
            discountedBalance,
            currentMonthlyBill,
          );
        }
        case "futureesttotal": {
          const allPackages = args[1] as PackageCardData[];
          const discountedBalance = args[2] as number;
          const currentMonthlyBill = args[3] as number;
          const promo = args[4] as ValidatePromoCodeVM;

          return (
            this.getCurrentBalance(
              promo,
              discountedBalance,
              currentMonthlyBill,
            ) + this.getNextBillTotalDelta(allPackages)
          );
        }
      }
    }
    return null;
  }

  getCurrentBalance(
    promo: ValidatePromoCodeVM,
    discountedBalance: number,
    currMonthlyBill: number,
  ): number {
    return promo?.promoCode ? discountedBalance : currMonthlyBill;
  }

  getNextBillTotalDelta(allPackages: PackageCardData[]): number {
    return allPackages
      .filter((x) => x.PackageType !== "One Time Charge")
      .reduce((acc, q) => {
        return (
          acc + (q.CurrentQuantity - q["InitialQuantity"]) * q.NextBillPrice
        );
      }, 0);
  }
}
