<div class="full-container-padding-side-15 p-t-xl p-b-xl scroll-content">
  <div class="detailsDetails">
    <div class="dspy-flex justifiedRow detailsHeading">
      <h4>
        <b data-testid="grantAccessTitle" class="title">
          Grant access to {{ orgNameFolderName }}</b
        >
      </h4>
    </div>
    <div class="row medium">
      <div class="col-sm-12 header_wrapper">
        <div class="row p-t-sm p-b-sm align-items-center">
          <span class="selectuser">Select Users</span>
          <div class="col-sm-3 search_wrapper">
            <div class="pad-left-top">
              <app-search-control
                id="primaryFrameSearchControl"
                [Configuration]="searchControlConfiguration"
                (SearchOccurred)="openSearchOverlay($event)"
              ></app-search-control>
              <p-overlayPanel #op>
                @if (isLoading) {
                  <div class="loading-section">
                    <rw-spinner
                      datapendoid="apphub-access-management-assign-spinner"
                      datatestid="apphub-access-management-assign-spinner"
                      spinnerclass="rw-spinner-primary"
                      size="sm"
                    >
                    </rw-spinner>
                    <span data-testid="loaderOverlay" class="loading">
                      Loading...
                    </span>
                  </div>
                }
                <ng-template pTemplate>
                  <p-table
                    data-testid="searchResults"
                    [value]="rnGrantAccessSearchResultVM"
                    selectionMode="single"
                    (onRowSelect)="searchOccurred($event)"
                    [rows]="10"
                    class="search_table"
                  >
                    <ng-template
                      pTemplate="body"
                      let-rowData
                      let-rnGrantAccessSearchResultVM
                    >
                      <tr
                        [pSelectableRow]="rowData"
                        (click)="rowDataviewO(rowData, op)"
                        [pSelectableRowDisabled]="
                          rnGrantAccessSearchResultVM?.User
                            ?.AvailableMappedDrivesCount === 0
                        "
                      >
                        <td
                          tooltip-placement="right"
                          [ngClass]="{
                            'is-disabled':
                              rnGrantAccessSearchResultVM?.User
                                ?.AvailableMappedDrivesCount === 0,
                          }"
                          escape="false"
                          [escape]="false"
                          (click)="closeSearchOverlay()"
                        >
                          <span
                            escape="false"
                            [escape]="false"
                            *ngIf="
                              rnGrantAccessSearchResultVM.SearchResultType ===
                              'org'
                            "
                            pTooltip="<h3>
                        <strong>{{
                              rnGrantAccessSearchResultVM.Organization.Name
                            }}</strong></h3>
                        <strong>Parent Account:</strong> {{
                              rnGrantAccessSearchResultVM.Organization
                                .ParentName
                            }}
                        <strong>Account Owner:</strong> {{
                              rnGrantAccessSearchResultVM.Organization
                                .AccountOwner
                            }}
                        <strong>Customer Since:</strong> {{
                              rnGrantAccessSearchResultVM.Organization
                                .CreatedDate | date
                            }}
                        &nbsp;
                        <strong>Business Address</strong>
                        {{
                              rnGrantAccessSearchResultVM.Organization.Address
                                .Address1
                            }}
                        {{
                              rnGrantAccessSearchResultVM.Organization.Address
                                .Address2
                            }}
                        {{
                              rnGrantAccessSearchResultVM.Organization.Address
                                .City
                            }} {{
                              rnGrantAccessSearchResultVM.Organization.Address
                                .StateProvince
                            }} {{
                              rnGrantAccessSearchResultVM.Organization.Address
                                .PostalCodeID
                            }}
                        {{
                              rnGrantAccessSearchResultVM.Organization.Address
                                .Country
                            }}"
                          >
                            <div class="data_row_wrapper">
                              <img
                                src="../../../../../assets/images/rn-business-office.png"
                                alt="icon"
                                loading="lazy"
                              />
                              <div class="data_row_text">
                                <span class="title">
                                  {{
                                    rnGrantAccessSearchResultVM?.Organization
                                      ?.Name
                                  }}
                                </span>
                                <span class="sub_title"> (all users) </span>
                              </div>
                              <span class="icon">+</span>
                            </div>
                          </span>
                          <span
                            escape="false"
                            [escape]="false"
                            *ngIf="
                              rnGrantAccessSearchResultVM.SearchResultType ===
                                'user' &&
                              rnGrantAccessSearchResultVM.User
                                .AvailableMappedDrivesCount !== 0
                            "
                            pTooltip="<div>
                        <h3><strong>{{
                              rnGrantAccessSearchResultVM.User?.FullName
                            }}</strong></h3>
                        <strong>Email Address:</strong> {{
                              rnGrantAccessSearchResultVM.User?.Email
                            }}
                        <strong>Account:</strong> {{
                              rnGrantAccessSearchResultVM.User?.OrganizationName
                            }}
                        <strong>Customer Since:</strong> {{
                              rnGrantAccessSearchResultVM.User?.CreatedDate
                                | date
                            }}
                        <strong>Role:</strong> {{
                              rnGrantAccessSearchResultVM.User?.UserType
                            }}
                        </div>"
                          >
                            <div class="data_row_wrapper">
                              <div class="data_row_text">
                                <span class="title">
                                  {{
                                    rnGrantAccessSearchResultVM?.User?.FullName
                                  }}
                                </span>
                                <span class="sub_title">
                                  {{ rnGrantAccessSearchResultVM?.User?.Email }}
                                </span>
                              </div>
                              <span class="icon">+</span>
                            </div>
                          </span>
                          <span
                            escape="false"
                            [escape]="false"
                            *ngIf="
                              rnGrantAccessSearchResultVM.SearchResultType ===
                                'user' &&
                              rnGrantAccessSearchResultVM.User
                                .AvailableMappedDrivesCount === 0
                            "
                            pTooltip="<div>
                        <h3><strong>{{
                              rnGrantAccessSearchResultVM.User?.FullName
                            }}</strong></h3>
                        <p style= 'color:red'><strong>Maximum Drive Mappings has been reached for this user.<br />To grant access to this user, a mapping must be removed from this user.</strong></p>
                        <strong>Email Address:</strong> {{
                              rnGrantAccessSearchResultVM.User?.Email
                            }}
                        <strong>Account:</strong> {{
                              rnGrantAccessSearchResultVM.User?.OrganizationName
                            }}
                        <strong>Customer Since:</strong> {{
                              rnGrantAccessSearchResultVM.User?.CreatedDate
                                | date
                            }}
                        <strong>Role:</strong> {{
                              rnGrantAccessSearchResultVM.User?.UserType
                            }}
                        </div>"
                          >
                            <div class="data_row_wrapper">
                              <div class="data_row_text">
                                <span class="title">
                                  {{
                                    rnGrantAccessSearchResultVM?.User?.FullName
                                  }}
                                </span>
                                <span class="sub_title">
                                  {{ rnGrantAccessSearchResultVM?.User?.Email }}
                                </span>
                              </div>
                              <span class="icon">+</span>
                            </div>
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ng-template>
              </p-overlayPanel>
            </div>
          </div>
        </div>
      </div>
      <span class="table_badge"
        >{{ dataSource?._data?._value.length || "0" }} users</span
      >
    </div>
  </div>
  <br />
  <div class="mat-table-container">
    <!-- <rn-grid class="flex-fill" [Configuration]="accessMgmtGridConfiguration" ></rn-grid> -->
    <mat-table
      data-testid="resultsTable"
      #table
      [dataSource]="dataSource"
      matSort
      #userTbSort="matSort"
    >
      <!-- Name Column -->
      <ng-container matColumnDef="FullName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell
          data-testid="fullNameText"
          *matCellDef="let element; let i = index; trackBy: trackByFn"
        >
          {{ element.FullName }}
        </mat-cell>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="Email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Email
        </mat-header-cell>
        <mat-cell
          data-testid="emailText"
          *matCellDef="let element; let i = index; trackBy: trackByFn"
        >
          {{ element.Email }}
        </mat-cell>
      </ng-container>

      <!-- Organization Column -->
      <ng-container matColumnDef="OrganizationName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Organization
        </mat-header-cell>
        <mat-cell
          data-testid="orgText"
          *matCellDef="let element; let i = index; trackBy: trackByFn"
        >
          {{ element.OrganizationName }}
        </mat-cell>
      </ng-container>

      <!-- remove Row -->
      <ng-container matColumnDef="remove">
        <th mat-header-cell style="border: none !important" *matHeaderCellDef>
          Action
        </th>
        <td
          mat-cell
          data-testid="actionText"
          *matCellDef="let element; let i = index; trackBy: trackByFn"
        >
          <button
            mat-icon-button
            class="removeCart"
            (click)="removeAt(element.UserID)"
          >
            <mat-icon data-testid="removeBtn">close</mat-icon>
          </button>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <!-- empty data text -->
    <div
      data-testid="noUsersText"
      *ngIf="!dataSource?._data?._value.length"
      class="no_data"
    >
      No users added, yet
    </div>

    <mat-paginator
      #paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]"
      [itemsPerPageLabel]="['Rows per page']"
      data-testid="paginator"
    >
    </mat-paginator>
  </div>
</div>
<div class="modal-footer copy-activation-link-footer">
  <app-button
    id="btnCancel"
    label="Cancel"
    buttonStyle="addUserTeritary"
    (clickActionEmitter)="dismiss()"
  >
  </app-button>
  <app-button
    id="btnGrantAccess"
    buttonStyle="primary"
    [disabled]="userGrantAccessBtnDisabled"
    showProcessing="true"
    [processing]="isProcessing"
    label="Grant access"
    (clickActionEmitter)="GrantAccess()"
  >
  </app-button>
</div>
