import { CurrencyPipe } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConstantsService } from "../../../services/constants/constants.service";
import { Observable, Subscription } from "rxjs";
import { RnDialogService } from "../../../services/dialog/rndialog.service";
import { IdService } from "../../../services/id/id.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import { NotificationDialogService } from "../../../services/notificationDialog/notification-dialog.service";
import { OrganizationLogicService } from "../../../services/organization-logic/organization-logic.service";
import {
  LookupService,
  OrderService,
  OrganizationService,
} from "../../../services/rnapi2-service/apis/api";
import { RnCommonSearch } from "../../../services/rnapi2-service/models/commonSearch-d";
import {
  RnCommonId,
  RnOrganizationConfigurationVM,
  RnOrganizationsProfileVM,
  RnPackPoolMV,
  RnRNOrderHeader,
  RnRNOrderItem,
} from "../../../services/rnapi2-service/models/models";
import { RnRNAddOnPayload } from "../../../services/rnapi2-service/models/rNAddOnPayload-d";
import { RnRNLicenseItem } from "../../../services/rnapi2-service/models/rNLicenseItem-d";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { GridColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-column-configuration";
import { GridConfiguration } from "../../third-party-wrappers/grid/configuration/grid-configuration";
import { GridHtmlColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-html-column-configuration";
import { BillingFormData } from "../billing-form/billing-form.data";
import { GenericDialogConfiguration } from "../generic-dialog/generic-dialog-configuration";
import { PackageCardData } from "../package-card/package-card-data";
import { PackageGroup } from "./package-group";
import { ValidatePromoCodeVM } from "./validate-promo-code-vm";
import { HttpResponse } from "@angular/common/http";
import { OrganizationCatalogInfoVM } from "src/app/shared/services/rnapi2-service/models/OrganizationCatalogInfoVM";
import { FeatureFlagService } from "../../../services/feature-flag/feature-flag.service";

@Component({
  selector: "app-change-package-inventory",
  templateUrl: "./change-package-inventory.component.html",
  styleUrls: ["./change-package-inventory.component.scss"],
})
export class ChangePackageInventoryComponent implements OnInit, OnDestroy {
  @Input() set OrgProfile(orgProfile: RnOrganizationsProfileVM) {
    this.orgProfile = orgProfile;
    this.disableQtyChange =
      this.orgProfile && this.orgProfile.Status !== "Active";
  }
  @Input() OrgConfig: RnOrganizationConfigurationVM;
  @Input() parentIsLoading = false;
  orgProfile: RnOrganizationsProfileVM;
  ChangeTableConfiguration: GridConfiguration;
  allPackages: PackageCardData[];
  initialPackages: PackageCardData[];
  categoryGroups: any[] = [];
  isLoading = true;
  cart: any[];
  totals = 0;
  currentPanel = 0;
  currentMonthlyBill: number;
  promoCodeIsValid = false;
  promoCodeWaiting = false;
  promoCodeError = false;
  inputPromoCode = "";
  validatedPromo: ValidatePromoCodeVM;
  canShowPromoCodeEntry: boolean;
  hostedDesktopPackageNames: string[];
  cancellations_phone: string;
  sales_phone: string;
  internalex_message: string;
  discountedBalance: number;
  showProcessingOverlay = false;
  disableQtyChange: boolean;
  disableAddQtyChange = false;
  intuitQbesOrgBasePackage: PackageCardData;
  intuitQbesAddonsMaxHint: string;
  intuitQbesMaxHint: string;
  buttonTooltip = "";
  intuitQbesAddonsSaveMaxHint: string;
  hasChanges = false;
  removedLastPackage = false;
  qbesAddonsOutOfCompliance = true;
  intuitBaseEntitlement: PackageCardData;
  availableSeatCount = 0;
  totalSeatCount = 0;
  externalHosting = 0;
  hideWispSkus = false;

  constructor(
    private organizationService: OrganizationService,
    private organizationLogicService: OrganizationLogicService,
    private lookupService: LookupService,
    private rnDialogService: RnDialogService,
    private notificationService: NotificationDialogService,
    private loggedInInfoService: LoggedInInfoService,
    private rnToastService: RnToastService,
    private orderService: OrderService,
    private constantsService: ConstantsService,
    private orgLogicService: OrganizationLogicService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private orgService: OrganizationService,
    private featureFlagService: FeatureFlagService,
  ) {}

  getInternalExclusiveTooltip(pkg): string {
    if (pkg.CatalogVisibility === "InternalExclusive") {
      return this.internalex_message;
    } else {
      return "";
    }
  }

  ngOnInit(): void {
    if (this.loggedInInfoService.GetWLPConfig) {
      const wlpConfigData = this.loggedInInfoService?.GetWLPConfig()?.data;
      if (wlpConfigData) {
        this.cancellations_phone =
          Object.values(wlpConfigData)[
            Object.keys(wlpConfigData).indexOf("cancellations_phone_number")
          ];
      }
      const payload = new RnCommonSearch();

      payload.search =
        "rn_direct_sales_phone_number,rn_internalexclusive_message";
      const getconfvalues = this.orgService
        ?.apiV2OrganizationsGetmultipleconfigurablevaluesPost(payload)
        .subscribe((response) => {
          this.sales_phone = response.data["rn_direct_sales_phone_number"];
          this.internalex_message =
            response.data["rn_internalexclusive_message"];
          if (!this.internalex_message) {
            this.internalex_message = "Please contact Sales at 888-210-0237";
          }
        });
      this.subscriptions.push(getconfvalues);
    }

    this.callMassiveSubscribe();
    this.canShowPromoCodeEntry =
      this.organizationLogicService.CanShowPromoCodeEntry();

    this.subscriptions.push(
      this.featureFlagService
        .getFlag("hide-wisp-cards", false)
        .subscribe((flagstatus) => {
          this.hideWispSkus = flagstatus;
        }),
    );
  }

  // start of massive subscribe function
  callMassiveSubscribe() {
    this.subscriptions.push(
      this.loadOrganizationPackageData().subscribe((r) => {
        if (r.body.Success) {
          this.isLoading = false;
          this.currentMonthlyBill = r.body.data.CurrentEstimatedBill;
          const responsePackages = r.body.data.CatalogItems.filter(
            (p) => "package" == p.PackageUIType,
          );
          this.externalHosting = r.body.data.CatalogItems.filter(
            (p) => true == p.HasExternalHosting,
          ).length;
          const packageCatalog = [];
          responsePackages.forEach((p) => {
            if (
              ((p.CatalogVisibility === "Internal" ||
                p.CatalogVisibility === "InternalExclusive") &&
                p.CurrentQuantity !== 0) ||
              (p.CatalogVisibility !== "Internal" &&
                p.CatalogVisibility !== "InternalExclusive")
            ) {
              const pkg = new PackageCardData();
              pkg.AssignFromRnCatalogItemVM(p);
              if (pkg.ExternallyPurchasable === undefined) {
                pkg.ExternallyPurchasable = true;
              }
              packageCatalog.push(pkg);
            }
          });
          this.hostedDesktopPackageNames = packageCatalog.map(
            (p) => p.PackageName,
          );
          const responseAddons = r.body.data.CatalogItems.filter(
            (p) => "addon" == p.PackageUIType,
          );
          let addonCatalog = [];
          responseAddons.forEach((p) => {
            if (
              ((p.CatalogVisibility === "Internal" ||
                p.CatalogVisibility === "InternalExclusive") &&
                p.CurrentQuantity !== 0) ||
              (p.CatalogVisibility !== "Internal" &&
                p.CatalogVisibility !== "InternalExclusive")
            ) {
              const pkg = new PackageCardData();
              pkg.AssignFromRnCatalogItemVM(p);
              if (pkg.ExternallyPurchasable === undefined) {
                pkg.ExternallyPurchasable = true;
              }
              addonCatalog.push(pkg);
            }
          });

          addonCatalog = addonCatalog.sort((a, b) => {
            return (
              a.PackageCategoryID - b.PackageCategoryID ||
              a.SortOrder - b.SortOrder
            );
          });

          this.allPackages = packageCatalog
            .concat(addonCatalog)
            .sort((a, b) => {
              if (a.PackageCategoryID === b.PackageCategoryID) {
                return a.SortOrder > b.SortOrder ? 1 : -1;
              }
              return a.PackageCategoryID > b.PackageCategoryID ? 1 : -1;
            });
          this.initialPackages = JSON.parse(JSON.stringify(this.allPackages));
          this.setupQbesData();

          let currentID = -1;
          let currentCatalogID = -1;
          let grp: PackageGroup;
          for (const pkg of this.allPackages) {
            // until we modify the package descriptions across the board, this kluge will have to do
            if (pkg.PackageDescHtml.indexOf("Review Package Details") >= 0) {
              pkg.PackageDescHtml = pkg.PackageDescHtml.replace(
                "Review Package Details",
                "Package Details",
              );
            }
            if (pkg.CatalogVisibility == "Unpurchasable") continue;

            // unfortunately, there's no unique ID for WISP packages, so we'll have to go by name
            if (
              !this.hideWispSkus ||
              (this.hideWispSkus && pkg.PackageName.indexOf("(WISP)") < 0)
            ) {
              if (pkg.PackageCategoryID != currentID) {
                if (currentID != -1) this.categoryGroups.push(grp);
                currentID = pkg.PackageCategoryID;
                currentCatalogID = pkg.CatalogCategoryID;
                grp = new PackageGroup();
                grp.Name =
                  pkg.PackageCategoryDescription ==
                    "For Accounting Professionals" || !pkg.CatalogCategoryName
                    ? "Packages"
                    : pkg.PackageCategoryDescription;
                if (grp.Name === "TPRO") {
                  grp.Name = "ADD-ONS";
                }
                grp.Description = pkg.CatalogCategoryName;
                grp.Values.push(pkg);
              } else {
                grp.Values.push(pkg);
              }
            }
          }
          if (grp.Values.length > 0) this.categoryGroups.push(grp);

          this.setupColumnsForTable();
          this.cart = packageCatalog
            .concat(addonCatalog)
            .filter((p) => p["InitialQuantity"] !== p.CurrentQuantity);
          this.ChangeTableConfiguration.GridData = this.cart;

          this.intuitQbesOrgBasePackage = this.getIntuitQbesOrgBasePackage();
          this.setTooltips();
        }
      }),
    );
  }

  setupColumnsForTable() {
    this.ChangeTableConfiguration = new GridConfiguration();
    this.ChangeTableConfiguration.FirstRow = 0;
    this.ChangeTableConfiguration.UsePaginator = false;
    this.ChangeTableConfiguration.IsLazyLoading = false;
    this.ChangeTableConfiguration.ShowCountHeader = false;
    this.ChangeTableConfiguration.GridClass = "invoiceTable";
    this.ChangeTableConfiguration.ColumnConfiguration = [];
    const typeCol = new GridColumnConfiguration(
      "Type",
      "Type",
      "direct-inventory-table-header",
      "",
    );
    typeCol.CustomDataRetrieval = (dataRow: any) => {
      const pkg = dataRow as RnPackPoolMV;
      return pkg.PackageCategoryName == "Add-ons" ? "Add-on" : "Package";
    };
    this.ChangeTableConfiguration.ColumnConfiguration.push(typeCol);
    this.ChangeTableConfiguration.ColumnConfiguration.push(
      new GridColumnConfiguration(
        "PackageName",
        "Product Name",
        "direct-inventory-table-header",
        "",
      ),
    );
    const quantityCol = new GridColumnConfiguration(
      "Quantity",
      "Quantity",
      "direct-inventory-table-header",
      "",
    );
    quantityCol.CustomDataRetrieval = (dataRow: any) => {
      const pkg = dataRow as RnPackPoolMV;
      const delta = pkg.CurrentQuantity - pkg["InitialQuantity"];
      const deltaStr = Math.abs(delta).toString();
      return delta < 0 ? `-${deltaStr}` : deltaStr;
    };
    this.ChangeTableConfiguration.ColumnConfiguration.push(quantityCol);
    const priceCol = new GridHtmlColumnConfiguration(
      "ListPrice",
      "Price",
      "col-30-width direct-inventory-table-header",
      "",
    );
    priceCol.GetHtmlDataForColumn = (dataRow: any) => {
      if (dataRow.DiscountPercent > 0) {
        const discountPersent: string = dataRow.DiscountPercent.toString();
        return (
          `<p>
        <div class="preDiscountedPrice">
          ` +
          this.currencyPipe.transform(dataRow.ListPrice, "USD") +
          `
        </div>
        <div>` +
          this.currencyPipe.transform(dataRow.DiscountPrice, "USD") +
          `<span> (` +
          discountPersent +
          `% OFF)</span>
        </div>
        </p>`
        );
      } else {
        return (
          `<span>
          ` +
          this.currencyPipe.transform(dataRow.ListPrice, "USD") +
          `
          </span>`
        );
      }
    };
    this.ChangeTableConfiguration.ColumnConfiguration.push(priceCol);
    const totalCol = new GridHtmlColumnConfiguration(
      "Total",
      "Total",
      "direct-inventory-table-header text-align-right",
      "",
    );
    totalCol.GetHtmlDataForColumn = (dataRow: any) => {
      if (dataRow.DiscountPercent > 0) {
        return (
          `<p>
        <div class="preDiscountedPrice">
          ` +
          this.currencyPipe.transform(dataRow.ListPrice, "USD") +
          `
        </div>
        <div>` +
          this.currencyPipe.transform(
            dataRow.DiscountPrice *
              (dataRow.CurrentQuantity - dataRow["InitialQuantity"]),
            "USD",
          ) +
          `</div>
        </p>`
        );
      } else {
        return (
          `<span>
          ` +
          this.currencyPipe.transform(
            dataRow.ListPrice *
              (dataRow.CurrentQuantity - dataRow["InitialQuantity"]),
            "USD",
          ) +
          `
          </span>`
        );
      }
    };
    totalCol.CustomDataRetrieval = (dataRow: any) => {
      const pkg = dataRow as RnPackPoolMV;
      const total = (
        (pkg.CurrentQuantity - pkg["InitialQuantity"]) *
        pkg.DiscountPrice
      ).toString();
      return this.currencyPipe.transform(total, "USD");
    };
    this.ChangeTableConfiguration.ColumnConfiguration.push(totalCol);
    this.ChangeTableConfiguration.RetrieveClassForCell = (col, rowData) => {
      const classText = "text-align-right";
      return col.FieldName === "Total" ? classText : "";
    };
  }

  getGroupPanelTitle(group): string {
    return group[0].SKU.indexOf("PKG") >= 0 ? "Packages" : "Add-ons";
  }

  updateChanges(isPkgQuantityZero = false): void {
    this.cart = this.allPackages.filter(
      (p) => p["InitialQuantity"] != p.CurrentQuantity,
    );
    if (this.cart.length > 0) {
      this.hasChanges = true;

      if (isPkgQuantityZero) {
        const payload: RnRNAddOnPayload = new RnRNAddOnPayload();
        payload.PaymentOnFileID =
          this.loggedInInfoService.GetLoggedInUserOrg().PaymentOnFileID;
        payload.OrganizationID = this.orgProfile.ID;
        payload.OrderItems = this.getOrderItems();
        payload.QbesLicenseItems = new Array<RnRNLicenseItem>();
        payload.PromotionCodeID =
          this.validatedPromo && this.validatedPromo.promoCodeIsValid
            ? this.validatedPromo.promoCode.ID
            : null;
        payload.ForceSkipEmail = false;
        const header: RnRNOrderHeader = {
          Order_XID: "",
          Order_Source: "AppHub",
          Order_Date: new Date().toISOString().slice(0, 10).replace(/-/g, "/"),
          SalesAgent_TAG: "",
        };
        payload.Header = header;
        this.orderService.apiV2OrdersPreflightPost(payload).subscribe(
          (response) => {
            this.removedLastPackage = false;
          },
          (response) => {
            if (
              response.error?.Messages.length > 0 &&
              response.error.Messages.filter((p) => p.Code === "28000").length >
                0
            ) {
              this.removedLastPackage = true;
            }
          },
          () => {
            this.setTooltips();
          },
        );
      }
    }
    this.totals = 0;
    this.cart.forEach(
      (q) =>
        (this.totals +=
          q.DiscountPrice * (q.CurrentQuantity - q["InitialQuantity"])),
    );
    this.ChangeTableConfiguration.GridData = this.cart;

    this.setupQbesData();
  }

  getIncrementedAccountingPackagesIDs(): number[] {
    return this.allPackages
      .filter((p) => p.CurrentQuantity - p["InitialQuantity"] > 0)
      .map((q) => q.PackageID);
  }

  validatePromoCode(): void {
    if (this.promoCodeIsValid || this.promoCodeWaiting) {
      return;
    }

    this.promoCodeWaiting = true;
    this.promoCodeError = false;
    this.promoCodeIsValid = false;
    this.validatedPromo = new ValidatePromoCodeVM();
    this.inputPromoCode = this.inputPromoCode.toUpperCase();
    const packageIDs = this.getIncrementedAccountingPackagesIDs();

    const GetLookupValidatepromocode = this.lookupService
      .apiV2LookupValidatepromocodeGet(
        this.inputPromoCode,
        packageIDs,
        this.orgProfile.IdThatPaysForThisOrg,
        "response",
      )
      .subscribe(
        (response) => {
          this.promoCodeWaiting = false;
          if (response.body.data.promoCodeIsValid) {
            this.promoCodeIsValid = true;
            this.validatedPromo = response.body.data;
            console.log("promo code validated");
            this.updateTableForPromo();
            this.updateChanges();
            this.organizationService
              .apiV2OrganizationsCalculateOrgBalanceNextDuePost(
                this.orgProfile.ID,
                this.validatedPromo.promoCode.ID,
              )
              .subscribe((response) => {
                this.discountedBalance =
                  Math.round(parseFloat(response.data) * 100) / 100;
              });
          } else {
            this.promoCodeError = true;
            console.log("promo code not valid");
          }
        },
        (response) => {
          console.log("failure while validating promo code");
        },
      );
    this.subscriptions.push(GetLookupValidatepromocode);
  }

  updateTableForPromo(): void {
    if (
      !this.validatedPromo.promoCode ||
      !this.validatedPromo.promoCodeIsValid
    ) {
      this.undoPromoChangesOnPrices();
      return;
    }
    if (this.validatedPromo.promoCode.PackageIds.indexOf(-1) >= 0) {
      // this promo applies to all accounting packages
      for (const pkg of this.allPackages) {
        if (pkg.PackageCategoryID == 1) {
          this.changePackagePricesPerPromo(pkg);
        } else {
          // not an accounting package. if we have any other discounts, remove them
          this.forceListPriceIfAnyDiscountExists(pkg);
        }
      }
    } else {
      const pIds = this.validatedPromo.promoCode.PackageIds;
      for (let c = 0; c < pIds.length; c++) {
        this.changePackagePricesPerPromo(
          this.allPackages.find((p) => p.PackageID == pIds[c]),
        );
      }
      for (const key of this.allPackages) {
        if (pIds.indexOf(key.PackageID) < 0) {
          this.forceListPriceIfAnyDiscountExists(key);
        }
      }
    }
  }

  undoPromoChangesOnPrices(): void {
    for (const p of this.allPackages) {
      const initPackage = this.initialPackages.find(
        (q) => q.PackageID == p.PackageID,
      );
      p.DiscountPrice = initPackage.DiscountPrice;
      p.DiscountPercent = initPackage.DiscountPercent;
      p.NextBillPrice = initPackage.NextBillPrice;
    }
  }

  changePackagePricesPerPromo(pkg: RnPackPoolMV): void {
    if (pkg) {
      const discount =
        (this.validatedPromo.promoCode.DiscountPercent / 100.0) * pkg.ListPrice;
      pkg.DiscountPrice = pkg.ListPrice - discount;
      pkg.DiscountPercent = this.validatedPromo.promoCode.DiscountPercent;

      if (
        this.validatedPromo.promoCode.IsPromoCodeActiveNextBillCycle ||
        this.validatedPromo.promoCode.PromoBillCycles > 1
      ) {
        pkg.NextBillPrice = pkg.DiscountPrice;
      }
    }
  }

  forceListPriceIfAnyDiscountExists(pkg: RnPackPoolMV): void {
    // removes discount from a package if a promo code is set
    if (
      pkg.DiscountPercent != 0 &&
      this.validatedPromo.promoCode.PackageIds.includes(pkg.PackageID)
    ) {
      pkg.DiscountPrice = pkg.ListPrice;
      pkg.DiscountPercent = 0;
      pkg.NextBillPrice = pkg.ListPrice;
    }
  }

  async cancelPackageMix() {
    if (this.hasChanges) {
      const confirmConfig = new GenericDialogConfiguration();
      confirmConfig.Title = "Discard Changes?";
      confirmConfig.StyleClass = "confirmation";
      confirmConfig.ConfirmButtonText = "YES, DISCARD CHANGES";
      confirmConfig.CancelButtonText = "NO, STAY ON PAGE";
      confirmConfig.CancelButtonStyleClass = "addUserTeritary";
      confirmConfig.ConfirmButtonStyleClass = "primary";
      confirmConfig.DialogHeaderClass = "modal-header no-border";
      confirmConfig.cancelButtonNoContentPadding = true;
      confirmConfig.confirmButtonNoContentPadding = true;
      confirmConfig.DialogFooterCancelClass = "right-spacing";
      confirmConfig.Message =
        "Are you sure you want to discard the changes you have made?";
      confirmConfig.Confirmed = async () => {
        await this.confirmCancel();
      };
      this.notificationService.ShowConfirmation(confirmConfig);
    } else {
      await this.confirmCancel();
    }
  }

  async confirmCancel(): Promise<void> {
    const queryParams = {
      packageState: null,
    };
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: "merge", // remove to replace all query params by provided
    });
  }

  goBackAndReload(): void {
    setTimeout(async () => {
      if (!this.loggedInInfoService.GetLoggedInUser()) {
        return;
      }
      this.showProcessingOverlay = false;
      const queryParams = {
        packageState: "inventory",
      };
      await this.router
        .navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: "merge", // remove to replace all query params by provided
        })
        .then(() => {
          window.location.reload();
        });
    }, 3000);
  }

  private canPurchaseNeedsPOF(): boolean {
    if (!this.orgProfile) {
      return false;
    }
    const canPurchaseRight = this.loggedInInfoService.loggedInUserHasRights([
      "PRCHDROPPK",
    ]);
    const canUpdateBilling = this.loggedInInfoService.loggedInUserHasRights([
      "UPDTBILING",
    ]);
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return false;
    }

    const isInternal = loggedInUser.IsInternal();
    const loggedInUserOrg = this.loggedInInfoService.GetLoggedInUserOrg();
    return this.orgLogicService.CanPurchasePackagesNeedPOF(
      canPurchaseRight,
      canUpdateBilling,
      isInternal,
      this.orgProfile,
      this.OrgConfig,
      loggedInUserOrg,
      loggedInUser.UserType,
    );
  }

  savePackageMix() {
    if (this.canPurchaseNeedsPOF()) {
      const confirmConfig = new GenericDialogConfiguration();
      confirmConfig.StyleClass = "confirmation modal-body-14";
      confirmConfig.Title = "No Payment Method set up";
      confirmConfig.Message =
        this.constantsService.adjustInventoryNoBillingInfoMessage;
      confirmConfig.ConfirmButtonStyleClass = "primary";
      confirmConfig.DialogHeaderClass = "modal-header no-border";
      confirmConfig.DialogFooterCancelClass = "right-spacing";
      confirmConfig.ConfirmButtonText = "CONTINUE";
      confirmConfig.CancelButtonStyleClass = "addUserTeritary";
      confirmConfig.MessageContainsHTML = false;
      confirmConfig.ShowCloseButton = true;
      confirmConfig.cancelButtonNoContentPadding = true;
      confirmConfig.confirmButtonNoContentPadding = true;
      confirmConfig.UseProgressButton = true;
      confirmConfig.KeepOpenAfterConfirm = true;
      confirmConfig.Confirmed = (dialogRef) => {
        dialogRef.CloseDialog();
        const data = new BillingFormData();
        data.organization = this.orgProfile;
        data.limitedRefresh = true;
        const billingFormDialog = this.rnDialogService.BillingFormDialog(data);
        billingFormDialog.beforeClosed().subscribe((s) => {
          if (s) {
            const confirmConfig = this.getConfirmSaveChangesDialogConfig();
            this.notificationService.ShowConfirmation(confirmConfig);
          }
        });
      };
      this.notificationService.ShowConfirmation(confirmConfig);
    } else {
      const confirmConfig = this.getConfirmSaveChangesDialogConfig();
      this.notificationService.ShowConfirmation(confirmConfig);
    }
  }

  getConfirmSaveChangesDialogConfig(): GenericDialogConfiguration {
    const cc = new GenericDialogConfiguration();
    cc.Title = "Save Changes?";
    cc.StyleClass = "confirmation modal-body-14";
    cc.ConfirmButtonText = "SAVE CHANGES";
    cc.CancelButtonText = "CANCEL";
    cc.MessageContainsHTML = true;
    cc.DialogFooterClass = "rightAlignButtons";
    cc.CancelButtonStyleClass = "addUserTeritary";
    cc.ConfirmButtonStyleClass = "primary";
    cc.DialogHeaderClass = "modal-header no-border";
    cc.DialogFooterCancelClass = "right-spacing";
    cc.cancelButtonNoContentPadding = true;
    cc.confirmButtonNoContentPadding = true;
    cc.Message =
      "Your monthly bill will change by " +
      this.currencyPipe.transform(this.totals, "USD") +
      " (plus tax where applicable).</p><br/><p>Do you want to continue saving changes?";
    cc.Confirmed = () => {
      this.confirmSaveChanges();
    };
    return cc;
  }

  confirmSaveChanges() {
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return;
    }

    const payload: RnRNAddOnPayload = new RnRNAddOnPayload();
    payload.PaymentOnFileID =
      this.loggedInInfoService.GetLoggedInUserOrg().PaymentOnFileID;
    payload.OrganizationID = this.orgProfile.ID;
    payload.OrderItems = this.getOrderItems();
    payload.QbesLicenseItems = new Array<RnRNLicenseItem>();
    payload.PromotionCodeID =
      this.validatedPromo && this.validatedPromo.promoCodeIsValid
        ? this.validatedPromo.promoCode.ID
        : null;
    payload.ForceSkipEmail = false;
    const header: RnRNOrderHeader = {
      Order_XID: IdService.makeID(),
      Order_Source: "AppHub",
      Order_Date: new Date().toISOString().slice(0, 10).replace(/-/g, "/"),
      SalesAgent_TAG: loggedInUser.UserID.toString(),
    };
    payload.Header = header;

    this.showProcessingOverlay = true;
    const errorMessage =
      "The order was not processed correctly. Try again in a few moments. If this continues to happen, please contact Customer Support.";
    this.orderService.apiV2OrdersCreateaddonPost(payload, "response").subscribe(
      (response) => {
        if (response.body && response.body.Success) {
          this.rnToastService.showSuccess(
            "You have successfully made changes to your Packages and Add-ons Inventory.",
          );
          this.goBackAndReload();
        } else {
          this.showProcessingOverlay = false;
          this.rnToastService.showError(errorMessage);
        }
      },
      (error: any) => {
        // check error code for last package.  Error code 28000 is shared between multiple errors.
        this.showProcessingOverlay = false;
        if (error?.error?.Messages[0]?.Code == "28000" && error?.error?.Messages[0]?.Description === "Order would reduce number of packages on organization to 0 or less") {
          const lastPackageTooltip = `Need to drop your final package and cancel your Rightworks account? Please contact us for ${this.cancellations_phone}.`;
          this.rnToastService.showError(lastPackageTooltip);
        } else if (error?.error?.Messages[0]?.Code == "28000" && error?.error?.Messages[0]?.Description === "Payment on file has expired.") {
          const paymentExpiredMessage = "Your payment on file has expired.  Please update your payment information to proceed.";
          this.rnToastService.showError(paymentExpiredMessage);
        } else {
          this.rnToastService.showError(errorMessage);
        }
      },
    );
  }

  getOrderItems() {
    const orderItems = [];
    const changedItems = this.allPackages.filter(
      (p) => p.CurrentQuantity != p["InitialQuantity"],
    );
    for (const item of changedItems) {
      const o: RnRNOrderItem = {
        PackageID: item.PackageID,
        Quantity: item.CurrentQuantity - item["InitialQuantity"],
        PromoCode: "-1",
      };
      orderItems.push(o);
    }
    return orderItems;
  }

  setupQbesData(): void {
    let initialQty = 0;
    let currQty = 0;
    const qbespurchasables = this.allPackages?.filter(
      (p) =>
        p.PackageCategoryID == 2 &&
        p.PackageType == "Entitlement" &&
        (p.CatalogVisibility == "Internal" ||
          p.CatalogVisibility === "InternalExclusive"),
    );
    const qbesUnpurchasables = this.allPackages?.filter(
      (p) =>
        p.PackageCategoryID == 2 &&
        p.PackageType == "Entitlement" &&
        p.CatalogVisibility == "Unpurchasable",
    );
    if (qbesUnpurchasables) {
      qbesUnpurchasables.forEach((q) => (initialQty += q.InitialQuantity));
      qbespurchasables.filter((q) => (currQty += q.CurrentQuantity));
    }
    if (
      currQty > initialQty &&
      qbespurchasables.length >= 0 &&
      qbesUnpurchasables.length > 0
    ) {
      this.availableSeatCount = currQty;
      this.totalSeatCount = initialQty;
      this.qbesAddonsOutOfCompliance = this.disableAddQtyChange = true;
    } else {
      this.qbesAddonsOutOfCompliance = this.disableAddQtyChange = false;
    }
  }
  setTooltips(): void {
    const wlpConfigData = this.loggedInInfoService?.GetWLPConfig()?.data;
    this.intuitQbesMaxHint = `The number of upgrades cannot exceed the number of ${this?.intuitQbesOrgBasePackage?.PackageName} seats you have purchased. ${Object.values(wlpConfigData)[Object.keys(wlpConfigData).indexOf("packages_addon_tab_message")]}`;
    if (this.qbesAddonsOutOfCompliance) {
      this.intuitQbesAddonsMaxHint = `The number of upgrades cannot exceed the number of ${this?.intuitQbesOrgBasePackage?.PackageName} seats you have purchased. ${Object.values(wlpConfigData)[Object.keys(wlpConfigData).indexOf("packages_addon_tab_message")]}`;
      this.buttonTooltip = this.intuitQbesOrgBasePackage
        ? `The number of Intuit upgrades (${this.availableSeatCount}) cannot exceed the number of ${this.intuitQbesOrgBasePackage?.PackageName} seats (${this.totalSeatCount}) you have purchased.<br><br>${Object.values(wlpConfigData)[Object.keys(wlpConfigData).indexOf("packages_addon_tab_message")]}`
        : "";
    } else {
      this.intuitQbesAddonsMaxHint = "";
      this.buttonTooltip = "";
    }
    if (this.removedLastPackage) {
      this.buttonTooltip = `Need to drop your final package and cancel your Rightworks account? Please contact us for ${this.cancellations_phone}.`;
    }
    if (!this.removedLastPackage && !this.disableAddQtyChange) {
      //need to reset it in case a change occured
      this.buttonTooltip = "";
    }
  }

  getIntuitQbesOrgBasePackage(): PackageCardData {
    const qbesBase = this.allPackages.filter(
      (p) =>
        p.PackageCategoryID == 2 &&
        p.PackageType == "Entitlement" &&
        p.CatalogVisibility == "Unpurchasable",
    );
    if (qbesBase) {
      return qbesBase[0];
    } else {
      return null;
    }
  }

  onPromoChange(): void {
    if (
      this.validatedPromo?.promoCode &&
      this.validatedPromo?.promoCode.PromoCode != this.inputPromoCode
    ) {
      this.clearPromo();
    } else if (this.promoCodeError) {
      this.promoCodeError = false;
      this.inputPromoCode = "";
    }
  }

  clearPromo(): void {
    if (this.promoCodeError) {
      this.promoCodeError = false;
      this.inputPromoCode = "";
    }
    this.promoCodeIsValid = false;
    this.validatedPromo = new ValidatePromoCodeVM();
    this.undoPromoChangesOnPrices();
    this.updateChanges();
  }

  public loadOrganizationPackageData(): Observable<
    HttpResponse<OrganizationCatalogInfoVM>
  > {
    const payload = new RnCommonId();
    if (this?.orgProfile) {
      (payload.Id = this.orgProfile.ID.toString()),
        (payload.AffectedOrganizationId = this.orgProfile.ID);
      return this.organizationService.apiV2OrganizationsGetcatalogitemsfororgPost(
        payload,
        "response",
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }
  closePanel(index: number) {
    this.currentPanel = index + 1;
  }
  private subscriptions: Subscription[] = [];
}
